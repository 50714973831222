import * as THREE from "three"
import { useRef, useReducer, useMemo } from "react"
import { Canvas, useFrame } from "@react-three/fiber"
import { Environment, Lightformer } from "@react-three/drei"
import { BallCollider, Physics, RigidBody } from "@react-three/rapier"
import { easing } from "maath"
import { Effects } from "./Effects"
import { randInt } from "three/src/math/MathUtils.js"

const accents = ["#e81416", "#ffa500", "#faeb36", "#79c314", "#487de7", "#70369d"]
const shuffle = (accent = 0) => [
	{ color: "#444", roughness: 0.1, metalness: 1 },
	{ color: "#444", roughness: 0.1, metalness: 1 },
	{ color: "#444", roughness: 0.1, metalness: 1 },
	{ color: "#444", roughness: 0.1, metalness: 1 },
	{ color: "white", roughness: 0, metalness: 0 },
	{ color: "white", roughness: 0, metalness: 0 },
	{ color: "white", roughness: 0, metalness: 0 },
	{ color: "white", roughness: 0, metalness: 0 },
	{ color: accents[accent], roughness: 0, metalness: 0.2, accent: true },
	{ color: accents[accent], roughness: 0, metalness: 0.2, accent: true },
	{ color: accents[accent], roughness: 0, metalness: 0.2, accent: true },
	{ color: accents[accent], roughness: 0, metalness: 0.2, accent: true },
	{
		color: accents[(accent + randInt(0, accents.length)) % accents.length],
		roughness: 1,
		metalness: 0,
		accent: true,
	},
]

export default function App(props) {
	const [accent, click] = useReducer((state) => ++state % accents.length, 0)
	const connectors = useMemo(() => shuffle(accent), [accent])
	return (
		<Canvas
			flat
			shadows
			onClick={click}
			dpr={[1, 1.5]}
			gl={{ antialias: false }}
			camera={{ position: [0, 0, 30], fov: 17.5, near: 10, far: 40 }}
			{...props}
		>
			<color attach="background" args={["#141622"]} />
			<Physics /*debug*/ timeStep="vary" gravity={[0, 0, 0]}>
				<Pointer />
				{connectors.map((props, i) => (
					<Sphere key={i} {...props} />
				))}
			</Physics>
			<Environment resolution={256}>
				<group rotation={[-Math.PI / 3, 0, 1]}>
					<Lightformer
						form="circle"
						intensity={100}
						rotation-x={Math.PI / 2}
						position={[0, 5, -9]}
						scale={2}
					/>
					<Lightformer
						form="circle"
						intensity={2}
						rotation-y={Math.PI / 2}
						position={[-5, 1, -1]}
						scale={2}
					/>
					<Lightformer
						form="circle"
						intensity={2}
						rotation-y={Math.PI / 2}
						position={[-5, -1, -1]}
						scale={2}
					/>
					<Lightformer
						form="circle"
						intensity={2}
						rotation-y={-Math.PI / 2}
						position={[10, 1, 0]}
						scale={8}
					/>
					<Lightformer
						form="ring"
						color="#4060ff"
						intensity={80}
						onUpdate={(self) => self.lookAt(0, 0, 0)}
						position={[10, 10, 0]}
						scale={10}
					/>
				</group>
			</Environment>
			<Effects />
		</Canvas>
	)
}

function Sphere({
	position,
	children,
	vec = new THREE.Vector3(),
	scale,
	r = THREE.MathUtils.randFloatSpread,
	accent,
	color = "white",
	...props
}) {
	const api = useRef()
	const ref = useRef()
	const pos = useMemo(() => position || [r(10), r(10), r(10)], [])
	useFrame((state, delta) => {
		delta = Math.min(0.1, delta)
		api.current?.applyImpulse(vec.copy(api.current.translation()).negate().multiplyScalar(0.2))
		easing.dampC(ref.current.material.color, color, 0.2, delta)
	})
	return (
		<RigidBody linearDamping={4} angularDamping={1} friction={0.1} position={pos} ref={api} colliders={false}>
			<BallCollider args={[1]} />
			<mesh ref={ref} castShadow receiveShadow>
				<sphereGeometry args={[1, 64, 64]} />
				<meshStandardMaterial {...props} />
				{children}
			</mesh>
		</RigidBody>
	)
}

function Pointer({ vec = new THREE.Vector3() }) {
	const ref = useRef()
	useFrame(({ mouse, viewport }) =>
		ref.current?.setNextKinematicTranslation(
			vec.set((mouse.x * viewport.width) / 2, (mouse.y * viewport.height) / 2, 0)
		)
	)
	return (
		<RigidBody position={[0, 0, 0]} type="kinematicPosition" colliders={false} ref={ref}>
			<BallCollider args={[1]} />
		</RigidBody>
	)
}
